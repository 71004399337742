/* You can add global styles to this file, and also import other style files */
@import "./assets/css/variables_light_color.css";
@import "./assets/css/variables_dark_color.css";
* {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
}

body {
    margin: 0;
    line-height: 18px;
}

html,
body {
    height: 100%;
    font-family: PT Sans, sans-serif;
}

::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    background: var(--soft-gray-color, #EAEAEA);
}

::-webkit-scrollbar-thumb {
    background: var(--bg-gray-color, #CDCDCD);
    border: 0;
    background-clip: padding-box;
    -webkit-border-radius: 4px;
}

::-webkit-scrollbar-track {
    background: var(--bg-transparent-color, transparent);
}

::-webkit-scrollbar-button {
    width: 1px;
    height: 0;
    background-color: var(--bg-transparent-color, transparent);
}

::-webkit-scrollbar-corner {
    background-color: var(--soft-gray-color, #EAEAEA);
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.loading-block {
    display: flex;
    position: fixed;
    width: 50px;
    height: 50px;
    top: 45%;
    left: 50%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 5px;
    font-size: 15px;
}
