.color-scheme-dark {
    --white-color: #FFF;
    --black-color: #000;

    --black-amo-list-text-color: #2F3842;

    --strong-gray-color: #AEAEAE;
    --border-primary-color: #E7E9E8;
    --onyx-color: #363B44;
    --dark-gray-color: #ABABAB;
    --steel-gray-color: #989DA2;
    --light-gray-color: #F3F3F3;
    --gun-metal-gray-color: #969AA0;
    --light-silver-color: #E8E8E8;
    --fortress-gray-color: #B7B7B7;
    --soft-gray-color: #EAEAEA;
    --soft-white-color: #FCFCFC;

    --regular-text-color: #2D3641;
    --header-title-text-color: #333941;
    --modal-text-color: #323B45;
    --modal-text-description-color: #3B444D;
    --gray-arrow-color: #9EA0A6;
    --selects-wrapper-border-color: #D7D8DA;
    --page-description-text-color: #91989C;
    --modal-border-color: #D3D6D7;
    --page-background-color: #F5F5F5;
    --modal-hover-color: #FDFDFD;

    --checkbox-border-color: #e8eaeb;
    --checkbox-circle-bg-color: #516570;
    --checkbox-circle-bg-checked-color: #4C8BF7;

    --table-row-border-color: #e8eaeb;
    --table-row-hover-color: #F8FAFF;
    --table-row-checked-text-color: #92989B;
    --table-row-checked-highlight-color: #008DD5;
    --table-header-text-color: #B5BDCA;
    --table-header-text-checked-color: #676E7A;
    --table-header-logo-text-color: #BEBEBE;
    --table-header-bg-color: #F1F5FA;

    --table-body-name-row-color: #0057a9;
    --table-body-checkmark-color: #2E3640;

    --status-icon-enabled-color: #F37A7A;
    --status-icon-disabled-color: #34C924;

    --save-button-text-color: #30363F;
    --save-button-border-color: #DCDEDF;
    --save-button-bg-color: #FFFFFF;
    --save-button-hover-bg-color: #FCFCFC;
    --save-button-text-role-save-color: #FFF;
    --save-button-border-role-save-color: #406ED9;
    --save-button-bg-role-save-color: #4C81FB;
    --save-button-hover-role-save-color: #4D85E6;
    --save-button-text-role-save-disabled-color: #92989b;
    --save-button-border-role-save-small-color: #406ED9;

    --cancel-button-text-role-color: #92989b;

    --input-disabled-color: #F8F8F8;
    --input-placeholder-color: #b5b5b5;

    --script-page-settings-block-text-color: rgba(255, 255, 255, 0.5);

    --editor-comment-color: #969C9F;

    --editor-console-button-text-color: #969C9F;
    --editor-console-button-text-hover-color: #5F6365;
    
    --group-item-removable-border-color: #c5c5c5;
    --group-item-border-color: #e8eaeb;

    --hooks-icon-color: var(--dark-orange-color);
    --braces-icon-color: #CE3E60;

    --copy-place-text-color: rgba(0, 0, 0, 0.50);
    --copy-icons: rgba(0, 0, 0, 0.25);

    --hover-popup-content-color: #4E5355;
    --hover-popup-text-color: #6B6D72;
    --hover-popup-border-color: #C5C5C5;

    --spinner-color: #158FD2;

    --error-text-color: #DA8089;
    --close-icon-color: #D3D3D3;
    --hooks-menu-underline-color: #B5B8BE;

    --img-qr-border-color: #BFBFBF;
    --icon-robocode-logo-color: #DEF100;

    --bg-default-overlay-color: rgba(54, 57, 66, 0.7); /* var(--bg-default-overlay-color) */

}
